import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// ROUTES
import App from './App';
import Home from './components/Home';

// CSS
import './assets/css/index.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  // { path: '/login', element: <Login /> },
  // { path: '/loginbackoffice', element: <LoginBackOffice /> },
  // { path: '/register', element: <Register /> },
  // { path: '/backoffice', element: <BackOffice /> },

  // {
  //   element: <App />,
  //   children: [
  //     { path: "/", element: <Home /> },
  //     { path: '/blog', element: <Blog /> },
  //     { path: '/company', element: <Company /> },
  //     { path: '/productcatalog', element: <Product_Catalog /> },
  //     { path: '/cart', element: <Cart /> },
  //     { path: '/product/:id', element: <ProductDetail /> },
  //     { path: '/checkout', element: <CheckOut /> },
  //     { path: '/selectaddress', element: <SelectAddress /> },
  //     { path: '/ourproducts', element: <OurProducts /> },
  //     { path: '/sustainability', element: <Sustainability /> },
  //     { path: '/contact', element: <Contactus /> },
  //     { path: '/petfood/:id', element: <PetFood /> },
      
  //   ],
  // },

  {
    element: <App />,
    children: [
        {
            path: "/", element: <Home />
        }
    ]
  }

]);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
