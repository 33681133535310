import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <Grid container sx={{ mt: 3 }}>
            <Grid item xs={12}>
                <Typography display={`flex`} justifyContent={`center`} fontSize={12} color={`#5f5e5c`}>
                    Design & System by&nbsp;<Link to="https://fb.com/jamebies" target="_blank">Sritpong</Link>
                </Typography>
            </Grid>
        </Grid>
    )
}

export default Footer;