import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";

export class BotService {
    private baseURL: string | undefined;
    private url: string | undefined;
    private instance: AxiosInstance;

    constructor(baseURL: string, timeout?: number | undefined | null) {
        this.baseURL = 'http://localhost:3001';
        this.url = baseURL;
        this.instance = axios.create({
            baseURL: this.baseURL,
            timeout: timeout || 3 * 1000,
            timeoutErrorMessage: "Time out!"
        });
    }

    getBaseURL = (): string | undefined => {
        return this.baseURL;
    }

    get = async (path: string, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
        return this.instance.get(path, config).then((res: AxiosResponse) => {
            return res;
        });
    }

    post = async (path: string, body: any, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
        return this.instance.post(path, body, config).then((res: AxiosResponse) => {
            return res;
        });
    }

    check = async () : Promise<AxiosResponse> => {
        return this.instance.post('/send', {
            url: this.url,
            method: 'get'
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    run = async (id: string) : Promise<AxiosResponse> => {
        return this.instance.post(`/send`, {
            url: `${this.url}/bot/reserve/${id}`,
            method: 'post'
        }, {}).then((res: AxiosResponse) => {
            return res;
        });
    }
}