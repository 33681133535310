import { useEffect, useRef, useState } from "react";
import { listBotsType } from "./types";
import { Link } from "react-router-dom";
import { BotService } from "./services/bot.service";
import Swal from "sweetalert2";
import Footer from "./components/Footer/Footer";

import {
    Container,
    Divider,
    Grid,
    Paper,
    Typography,
    OutlinedInput,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const App = () => {
    const botUrlRef = useRef<HTMLInputElement>(null);
    const [listBots, setListBots] = useState<listBotsType[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        reloadBotData();
    }, []);

    const reloadBotData = () => {
        setLoaded(false);
        const getBotData : string | null = localStorage.getItem('listBots');
        
        if(getBotData === null)
        {
            localStorage.setItem('listBots', JSON.stringify([]));
            return setListBots([]);
        }

        setListBots(JSON.parse(getBotData));
        checkBotData(JSON.parse(getBotData));
    }

    const checkBotData = async (botData: listBotsType[]) => {
        let temp_botData: listBotsType[] = [];
        temp_botData = [...botData];
        await Promise.all(temp_botData.map(async (bot:listBotsType) => {
            try {
                const service = new BotService(bot.url, 10 * 1000);
                const botDataRes = (await service.check()).data;
                if(botDataRes && botDataRes.status_code === 200)
                {
                    const botIndex: number = temp_botData.findIndex((element:listBotsType) => element.url === bot.url);
                    
                    if(botIndex === -1)
                    {
                        return;
                    }
                    
                    temp_botData[botIndex].ip_address = botDataRes.current_ip_address;
                    temp_botData[botIndex].version = botDataRes.version;
                    temp_botData[botIndex].status = 'พร้อมใช้งาน';
                }
            } catch (err) {
                const botIndex: number = temp_botData.findIndex((element:listBotsType) => element.url === bot.url);
                
                if(botIndex === -1)
                {
                    return;
                }

                temp_botData[botIndex].status = 'ไม่พร้อมใช้งาน';
            }
        }));

        setListBots(temp_botData);
        setLoaded(true);
    }

    const handleAddBot = () => {
        if(botUrlRef.current !== null)
        {
            const add_url : string = botUrlRef.current.value;
            if(add_url === '')
            {
                return;
            }

            const getBotData: string | null = localStorage.getItem('listBots');
            const botData: listBotsType[] = getBotData === null ? [] : JSON.parse(getBotData);
            const findIndex: number = botData.findIndex((element:listBotsType) => element.url === add_url);
            if(findIndex !== -1)
            {
                return;
            }

            botData.push({url: add_url});
            localStorage.setItem('listBots', JSON.stringify(botData));
            botUrlRef.current.value = '';
            reloadBotData();
        }
    }

    const handleDeleteBot = (url: string) => {
        Swal.fire({
            icon: 'warning',
            title: 'แจ้งเตือน',
            text: `คุณต้องการที่จะลบ ${url} ใช่หรือไม่`,
            confirmButtonText: 'ยืนยัน',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก'
        }).then((result) => {
            if(!result.isConfirmed)
            {
                return;
            }

            const getBotData: string | null = localStorage.getItem('listBots');
            let botData: listBotsType[] = getBotData === null ? [] : JSON.parse(getBotData);
            botData = botData.filter((element: listBotsType) => element.url !== url);
            localStorage.setItem('listBots', JSON.stringify(botData));

            reloadBotData();
        });
    }

    const handleRunBot = (url: string) => {
        Swal.fire({
            icon: 'info',
            title: 'กรุณากรอก ID',
            text: `(${url})`,
            input: "text",
            confirmButtonText: 'ยืนยัน',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก'
        }).then(async (result) => {
            if(result.isConfirmed && result.value !== "")
            {
                try {
                    const id: string = result.value;
                    const service = new BotService(url, 600 * 1000);
                    Swal.fire({
                        icon: 'success',
                        title: 'แจ้งเตือน',
                        text: 'สั่งรัน BOT เรียบร้อยแล้ว',
                        confirmButtonText: 'ตกลง',
                    });
                    const { data } = await service.run(id);
                    if(data)
                    {
                        Swal.fire({
                            icon: 'info',
                            title: `Logs ${url} (ID: ${id})`,
                            text: JSON.stringify(data),
                            showConfirmButton: false,
                            showCancelButton: true,
                            cancelButtonText: 'ปิด'
                        });
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        })
    }

    return (
        <Container sx={{ my: 10 }}>
            <Grid container>
                <Grid item xs={12} component={Paper} p={3}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography color={`black`} fontSize={20}>
                                จัดการ BOT
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 2, mt: 1 }}>
                            <Divider />
                        </Grid>
                        <Grid item xs={6} px={1}>
                            <OutlinedInput
                                name='input_bot_url'
                                type={`text`}
                                size='small'
                                fullWidth
                                autoComplete="input_bot_url"
                                inputRef={botUrlRef}
                                placeholder="URL ของบอท"
                            />
                        </Grid>
                        <Grid item xs={6} px={1}>
                            <Button sx={
                                {
                                    backgroundColor: '#7989ff',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: '#606dcc',
                                        color: 'white'
                                    }
                                }
                            } fullWidth onClick={handleAddBot} disabled={!loaded}>
                                เพิ่มบอท
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper} sx={{ my: 2 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">URL</TableCell>
                                            <TableCell align="center">สถานะ</TableCell>
                                            <TableCell align="center">IP ADDRESS</TableCell>
                                            <TableCell align="center">Bot version</TableCell>
                                            <TableCell align="center">จัดการ</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            listBots.length > 0 ? listBots.map((bot:listBotsType, index:number) => {
                                                return (
                                                    <TableRow key={`bot-list-${index}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell align="center">
                                                            <Link to={`${bot.url}`} target="_blank">
                                                                {
                                                                    bot.url
                                                                }
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {
                                                                bot.status || "กำลังตรวจสอบ"
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {
                                                                bot.ip_address || "ไม่ทราบ"
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {
                                                                bot.version || "ไม่ทราบ"
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <IconButton sx={
                                                                {
                                                                    mx: .5,
                                                                    backgroundColor: '#7fba00',
                                                                    '&:hover': {
                                                                        backgroundColor: '#5c8600',
                                                                    },
                                                                    '&:disabled': {
                                                                        backgroundColor: '#5c8600',
                                                                    }
                                                                }
                                                            } disabled={!(bot.status === 'พร้อมใช้งาน')} onClick={() => handleRunBot(bot.url)}>
                                                                <PlayArrowIcon fontSize="small" sx={{ color: 'white' }} />
                                                            </IconButton>
                                                            <IconButton sx={
                                                                {
                                                                    mx: .5,
                                                                    backgroundColor: '#ff0000',
                                                                    '&:hover': {
                                                                        backgroundColor: '#cc0000',
                                                                    },
                                                                    '&:disabled': {
                                                                        backgroundColor: '#cc0000',
                                                                    }
                                                                }
                                                            } disabled={!loaded} onClick={() => handleDeleteBot(bot.url)}>
                                                                <DeleteIcon fontSize="small" sx={{ color: 'white' }} />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }) : null
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </Container>
    )
}

export default App;